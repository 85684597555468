import { useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { PageLayout, SearchUserInfo, AztecContainer, UserInfoForm } from '../../components';
import { GlobalContext } from '../../App';
import { routes, inspectionRequiredFields, API_URL } from '../../conf';
import { SearchBy } from '../../shared/enums';
import { useHandleFormSubmit } from '../../hooks/use-handle-form-submit';
import { TOAST_TYPE } from '../../hooks/use-toast';

export const InspectionInitPage = () => {
  const { 
    userInformations,
    vinNumber, 
    setVinNumber, 
    speedCarWorker, 
    setTechnicalReviewId, 
    aztecData, 
    toast,
  } = useContext(GlobalContext);
  const { phone, inspectionDate, insuranceDate, vehicleId, hasOutdatedData, scanId } = userInformations;
  const { person_id, contractor_head, contractor_service } = speedCarWorker;
  const { triggerToast } = toast;

  const navigate = useNavigate();
  const { handleSubmit, errorMessage, validated, isLoading } = useHandleFormSubmit(async () => {
      await axios
      .post(API_URL + '/api/scu/add-client', {
        phone_no: phone,
        next_tech_exam_date: inspectionDate,
        insurance_date_to: insuranceDate,
        person: {
          person_id,
          contractor_head,
          contractor_service
        },
        has_outdated_data: hasOutdatedData,
        has_technical_review: true,
        vehicle_id: vehicleId,
        aztec_data: aztecData,
        token: localStorage.getItem('token'),
        is_technical_review_approval: true,
        is_insurance: true,
        scan_id: scanId,
      },
      )
      .then((response) => {
        const technicalReviewId = response.data.data.technical_review;
        setTechnicalReviewId(technicalReviewId);
        if (response.data.success) {
          triggerToast('Klient został pomyślnie dodany', TOAST_TYPE.SUCCESS);
          navigate(routes.inspectionPhotosPage);
        } else {
          triggerToast('Nie udało się dodać klienta - błąd wewnętrzny serwera', TOAST_TYPE.DANGER);
        }
      })
      .catch(() => {
        triggerToast('Nie udało się dodać klienta - błąd zewnętrzny serwera', TOAST_TYPE.DANGER);
      })
    }
  );

  return (
    <PageLayout title="Przegląd techniczny">
      <div className="aztec-container d-flex justify-content-between">
        <AztecContainer />
        <SearchUserInfo text="Wyszukaj VIN" action={setVinNumber} value={vinNumber} searchBy={SearchBy.VIN_NUMBER} />
      </div>
      <UserInfoForm
        userInformations={userInformations}
        handleSubmit={handleSubmit}
        validated={validated}
        isLoading={isLoading}
        errorMessage={errorMessage}
        requiredUserInfoFields={inspectionRequiredFields}
      />
    </PageLayout>
  );
};