export const routes = {
  homepage: '/',
  inspectionInitPage: '/inspectioninitpage',
  inspectionPhotosPage: '/inspectionphotospage',
  inspectionResultPage: '/inspectionresultpage',
  inspectionScanRodoPage: '/inspectionscanrodopage',
  insuranceInitPage: '/insuranceinitpage',
  insuranceScanRodoPage: '/insuranescanrodopage',
  policyDocumentationInitPage: '/policydocumentationinitpage',
  scanRodoRequest: '/scanrodorequest',
  scannerPage: '/scannerpage',
  kukosoftPage: '/kukosoftpage',
  chooseSpeedCarWorker: '/choosespeedcarworker',
  chooseSpeedCarPlace: '/choosespeedcarplace',
};
