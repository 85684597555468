import { useContext, useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import dayjs from 'dayjs';

import { GlobalContext } from '../App';
import { SaveButton } from '../components';
import { Alert, ALERT_TYPE } from './alert.component';
import { TOAST_TYPE } from '../hooks/use-toast';
import { TIME_DELAY, API_URL } from '../conf';

export const UserInfoForm = ({ handleSubmit, validated, errorMessage, requiredUserInfoFields, isLoading, isInspection = true }) => {
  const { setUserInformations, setSpeedCarWorker, userInformations, toast, speedCarWorker } = useContext(GlobalContext);
  const [previousInspectionDate, setPreviousInspectionDate] = useState(userInformations.inspectionDate || '');
  const { triggerToast } = toast;
  const [workers, setWorkers] = useState([]);
  const onChange = (e) => {
    const selectedUser = workers.find((user) => user.person_id === e.currentTarget.value);
    setSpeedCarWorker(selectedUser);
  };

  const oneYearFromNow = dayjs().add(1, 'year').format('YYYY-MM-DD');

  useEffect(() => {
    if (userInformations.inspectionDate !== oneYearFromNow) {
      setPreviousInspectionDate(userInformations.inspectionDate);
    }
  }, [userInformations.inspectionDate, oneYearFromNow]);

  useEffect(() => {
    axios
      .post(API_URL + '/api/scu/search/workers', { token: localStorage.getItem('token'), deviceId: localStorage.getItem('deviceId') })
      .then((response) => {
        const workersList = response.data.data;
        if (workersList.length === 0) {
          triggerToast('Urządzenie nie ma dodanych żadnych pracowników', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
        }
        setWorkers(workersList);
      })
      .catch(() => {
        triggerToast('Nie udało się pobrać listy pracowników - błąd serwera', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
      });
  }, []);

  return (
    <div className="user-form-container mt-5">
      <Form
        className="d-flex flex-column justify-content-start align-items-center"
        onSubmit={handleSubmit}
        noValidate
        validated={validated}>
        <Form.Group>
          <div className="d-flex justify-content-between">
            <h1 className="input-label">Nieaktualne dane klienta</h1>
            <Form.Check
              type="checkbox"
              className="checkbox-button"
              onChange={(e) => {
                setUserInformations({ ...userInformations, hasOutdatedData: !e.currentTarget.checked });
              }}
              defaultChecked={false}
            />
          </div>
          <h1 className="input-label">Telefon klienta</h1>
          <Form.Control
            required={requiredUserInfoFields.phone}
            className="input-type-text"
            type="number"
            value={userInformations.phone}
            onChange={(e) => {
              const value = e.currentTarget.value;
              if (value.length <= 9) {
                  setUserInformations({ ...userInformations, phone: value });
              }
          }}
          />
          <h1 className="input-label">Numer rejestracyjny</h1>
          <Form.Control
            required={requiredUserInfoFields.phone}
            className="input-type-text"
            type="text"
            value={userInformations.registryNumber}
            onChange={(e) => setUserInformations({ ...userInformations, registryNumber: e.currentTarget.value })}
            disabled={userInformations.existingUser}
          />
          <h1 className="input-label">Imię</h1>
          <Form.Control
            placeholder="np. Jan"
            required={requiredUserInfoFields.firstName}
            className="input-type-text"
            type="text"
            value={userInformations.firstName}
            onChange={(e) => setUserInformations({ ...userInformations, firstName: e.currentTarget.value })}
            disabled={userInformations.existingUser}
          />
          <h1 className="input-label">Nazwisko</h1>
          <Form.Control
            placeholder="np. Kowalski"
            required={requiredUserInfoFields.lastName}
            className="input-type-text"
            type="text"
            value={userInformations.lastName}
            onChange={(e) => setUserInformations({ ...userInformations, lastName: e.currentTarget.value })}
            disabled={userInformations.existingUser}
          />
          {isInspection && (
            <div className="d-flex justify-content-between">
              <h1 className="input-label">Wykonuję badanie techniczne</h1>
              <Form.Check
                type="checkbox"
                className="checkbox-button"
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setUserInformations({ ...userInformations, inspectionDate: oneYearFromNow });
                  } else {
                    setUserInformations({ ...userInformations, inspectionDate: previousInspectionDate });
                  }
                }}
                defaultChecked={false}
              />
            </div>
          )}
          <h1 className="input-label">Data kolejnego badania technicznego</h1>
          <Form.Control
            required={requiredUserInfoFields.inspectionDate}
            className="input-type-text"
            type="date"
            value={userInformations ? userInformations.inspectionDate : (e) => e.currentTarget.value}
            onChange={(e) => {
              setUserInformations({
                ...userInformations,
                inspectionDate: e.currentTarget.value
              });
            }}
          />
          <h1 className="input-label">Okres ubezpieczenia do</h1>
          <Form.Control
            required={requiredUserInfoFields.insuranceDate}
            className="input-type-text"
            type="date"
            value={userInformations ? userInformations.insuranceDate : (e) => e.currentTarget.value}
            onChange={(e) => {
              setUserInformations({
                ...userInformations,
                insuranceDate: e.currentTarget.value
              });
            }}
          />
          <h1 className="input-label">Użytkownik skanujący</h1>
          <Form.Select
            required={requiredUserInfoFields.workerSelect}
            className="input-type-text"
            type="select"
            onChange={(e) => onChange(e)}>
            <option value={speedCarWorker.id}>{speedCarWorker.first_name} {speedCarWorker.surname}</option>
            {workers.map((user) => {
              const { person_id, first_name, surname } = user;
              return (
                <option key={person_id} value={person_id}>
                  {first_name + ' ' + surname}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            <Alert message={errorMessage} />
          </Form.Control.Feedback>
        </Form.Group>
        {userInformations.hasInsurance && (
          <Alert message="W systemie znajduje sie nieodebrana polisa klienta!" variant={ALERT_TYPE.WARNING} />
        )}
        <SaveButton isLoading={isLoading} />
      </Form>
    </div>
  );
};