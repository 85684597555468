import { useState } from 'react';
import { TIME_DELAY } from '../conf/time-delays';

export const TOAST_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark'
};

export const useToast = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    text: '',
    type: TOAST_TYPE.PRIMARY,
    delay: 4000
  });

  const triggerToast = (text, type = TOAST_TYPE.PRIMARY, delay = TIME_DELAY.MEDIUM) => {
    setToastConfig({ text, type, delay });
    setShowToast(true);
  };

  return {
    showToast,
    setShowToast,
    toastConfig,
    triggerToast
  };
};
