import { createContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { defaultUserInformationsState } from './appState/default-user-information-state';

import {
  StartPage,
  InspectionInitPage,
  InspectionPhotosPage,
  InspectionScanRodoPage,
  InspectionResultPage,
  PolicyDocumentationInitPage,
  InsuranceInitPage,
  ScanRodoRequest,
  InsuranceScanRodoPage
} from './pages';
import { routes } from './conf';
import { useToast } from './hooks/use-toast';
import { ScannerPage } from './pages/scanner/scanner-page';
import { defaultSpeedCarUserInformationsState } from './appState/default-speed-car-user-information-state';
import { KukosoftPage } from './pages/kukosoft/kukosoft.page';
import { ChooseSpeedCarWorker } from './pages/choose-speedcar-worker/choose-speed-car-worker';
import { ChooseSpeedCarPlace } from './pages/choose-speed-car-place/choose-speed-car-place';

export const GlobalContext = createContext({});

export const App = () => {
  const [registryNumber, setRegistryNumber] = useState(null);
  const [vinNumber, setVinNumber] = useState(null);
  const [aztecScannerOn, setAztecScannerOn] = useState(false);
  const [userInformations, setUserInformations] = useState(defaultUserInformationsState);
  const [speedCarWorker, setSpeedCarWorker] = useState(defaultSpeedCarUserInformationsState);
  const [technicalReviewId, setTechnicalReviewId] = useState(null);
  const [aztecData, setAztecData] = useState(null);
  const [location, setLocation] = useState(null);
  const toast = useToast();

  return (
    <div id="App">
      <GlobalContext.Provider
        value={{
          userInformations,
          registryNumber,
          vinNumber,
          speedCarWorker,
          aztecScannerOn,
          toast,
          technicalReviewId,
          aztecData,
          location,
          setRegistryNumber,
          setVinNumber,
          setSpeedCarWorker,
          setAztecScannerOn,
          setUserInformations,
          setTechnicalReviewId,
          setAztecData,
          setLocation,
        }}>
        <Routes>
          <Route exact path="/" element={<StartPage />} />
          <Route path={routes.inspectionInitPage} element={<InspectionInitPage />} />
          <Route path={routes.inspectionPhotosPage} element={<InspectionPhotosPage />} />
          <Route path={routes.inspectionResultPage} element={<InspectionResultPage />} />
          <Route path={routes.inspectionScanRodoPage} element={<InspectionScanRodoPage />} />
          <Route path={routes.insuranceInitPage} element={<InsuranceInitPage />} />
          <Route path={routes.insuranceScanRodoPage} element={<InsuranceScanRodoPage />} />
          <Route path={routes.scanRodoRequest} element={<ScanRodoRequest />} />
          <Route path={routes.policyDocumentationInitPage} element={<PolicyDocumentationInitPage />} />
          <Route path={routes.policyDocumentationInitPage} element={<PolicyDocumentationInitPage />} />
          <Route path={routes.scannerPage} element={<ScannerPage />} />
          <Route path={routes.chooseSpeedCarWorker} element={<ChooseSpeedCarWorker />} />
          <Route path={routes.kukosoftPage} element={<KukosoftPage />} />
          <Route path={routes.chooseSpeedCarPlace} element={<ChooseSpeedCarPlace />} />
        </Routes>
      </GlobalContext.Provider>
    </div>
  );
};
