import { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { GlobalContext } from '../../App';
import { AddPageButton, PageLayout, PhotoBox, SaveButton } from '../../components';
import { routes, API_URL } from '../../conf';
import { useHandleFormSubmit } from '../../hooks/use-handle-form-submit';
import { Alert } from '../../components/alert.component';
import { TOAST_TYPE } from '../../hooks/use-toast';
import { initialScanRodoFields } from './initial-rodo-fields';
import { useMultiplePhotoBox } from '../../hooks/use-multiple-photo-box';

export const InspectionScanRodoPage = () => {
  const { technicalReviewId, speedCarWorker, toast } = useContext(GlobalContext);
  const { triggerToast } = toast;
  const { person_id, contractor_head, contractor_service } = speedCarWorker;
  const navigate = useNavigate();

  const {
    fields: rodoFields,
    setFields: setRodoFields,
    uploadedFields: uploadedRodoFields,
    setUploadedFields: setUploadedRodoFields,
    uploadedValues: uploadedRodoFieldsValues,
    handleAddPage: handleAddRodoPage
  } = useMultiplePhotoBox(initialScanRodoFields);

  const { handleSubmit, errorMessage, validated, isLoading } = useHandleFormSubmit(() =>
    axios
      .post(API_URL + '/api/scu/add-rodo-by-inspection-id', {
        technical_review_id: technicalReviewId,
        rodo_file: uploadedRodoFieldsValues,
        person: {
          person_id,
          contractor_head,
          contractor_service
        },
        has_technical_review: true,
        token: localStorage.getItem('token')
      })
      .then((response) => {
        if (response.data.success) {
          triggerToast('Zdjęcia zostały pomyślnie zapisane', TOAST_TYPE.SUCCESS);
          navigate(routes.homepage);
        } else {
          const errorMessage = response.data.info || 'Nie udało się zapisać zdjęć - błąd wewnętrzny serwera';
          triggerToast(errorMessage , TOAST_TYPE.DANGER);
        }
      })
      .catch(() => {
        triggerToast('Nie udało się zapisać zdjęć - błąd zewnętrzny serwera', TOAST_TYPE.DANGER);
      })
  );

  return (
    <PageLayout title="Przegląd techniczny">
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <h2 className="text-center mt-5">Zeskanuj wniosek RODO</h2>
        <Form.Group>
          <div className="d-flex flex-row justify-content-around flex-wrap">
            {rodoFields.map((field) => (
              <PhotoBox
                errorMessage={errorMessage}
                required={field.required}
                key={field.id}
                id={field.id}
                placeholderImage={field.placeholderImage}
                title={field.title}
                generated={field.generated}
                state={rodoFields}
                setPictureState={setUploadedRodoFields}
                setState={setRodoFields}
                pictureState={uploadedRodoFields}
              />
            ))}
            <AddPageButton action={handleAddRodoPage} fieldToAdd={rodoFields.length} disabled={false} />
          </div>
        </Form.Group>
        <Alert style={styles.alert} message={errorMessage} />
        <SaveButton isLoading={isLoading} />
      </Form>
    </PageLayout>
  );
};

const styles = {
  appContainer: {
    backgroundColor: '#eaeaea',
    minHeight: '100vh',
    height: '100%',
    width: '100%',
    padding: '2rem'
  },
  logo: {
    height: '15vmin',
    margin: '2%',
    cursor: 'pointer'
  },
  alert: {
    marginTop: '1.5rem'
  }
};
