export const defaultUserInformationsState = {
  phone: '',
  firstName: '',
  lastName: '',
  inspectionDate: '',
  insuranceDate: '',
  hasInsurance: false,
  policies: [],
  existingUser: false,
  vehicleId: '',
  hasOutdatedData: true,
  registryNumber: '',
  scanId: '',
};
