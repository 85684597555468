import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { GlobalContext } from '../App';

const VIDEO_CONTAINER_ID = 'camera_container';

export const Scanner = ({ customScanHandler, callToBackend }) => {
  const navigate = useNavigate();
  const { location } = useContext(GlobalContext);

  const onVideoInitializedSuccessHandler = () => {
    console.log('init camera succes');
  };
  const onVideoInitializedErrorHandler = (err) => {
    console.log('init camera error', err);
  };

  const onScanSuccessHandler = (aztec) => {
    callToBackend(aztec);
    navigate(location);

    return;
  };

  const handler = (aztec) => {
    if (customScanHandler) {
      customScanHandler(aztec);
    } else {
      onScanSuccessHandler(aztec);
    }
  };

  useEffect(() => {
    let decoder = initNeptis();
    return () => {
      console.log('cleaning up');
      delete decoder.onScanSuccessHandler;
      delete decoder.onVideoInitializedSuccessHandler;
      delete decoder.onVideoInitializedErrorHandler;
      delete decoder.videoContainerId;
      delete decoder.videoContainerReader;
    };
  }, []);
  /* eslint-disable */
  const initNeptis = () => {
    const decoder =
      typeof Neptis !== 'undefined' &&
      new Neptis.RegistrationCertificateDecoder(
        VIDEO_CONTAINER_ID,
        onVideoInitializedSuccessHandler,
        onVideoInitializedErrorHandler,
        handler,
      );
    return decoder;
  };

  const videoStyles = { width: '100vw', height: '100vh', maxWidth: '100%', background: '#282828' };

  return (
    <div>
      {<div style={videoStyles} id={VIDEO_CONTAINER_ID}></div>}
    </div>
  );
};
