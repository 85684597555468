export const initialTerminationsFields = [
  {
    id: 'terminations',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];

export const initialTerminationsFieldsTwo = [
  {
    id: 'terminations_2',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];

export const initialTerminationsFieldsThree = [
  {
    id: 'terminations_3',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];
