export const initialScanPolicyPhotoFields = [
  {
    id: 'policyDocument',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];

export const initialScanPolicyPhotoFieldsTwo = [
  {
    id: 'policyDocument_2',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];

export const initialScanPolicyPhotoFieldsThree = [
  {
    id: 'policyDocument_3',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];
