import { useState } from 'react';
import { createNewPage } from '../services';

export const useMultiplePhotoBox = (initialState) => {
  const [fields, setFields] = useState(initialState);
  const [uploadedFields, setUploadedFields] = useState([]);
  const uploadedValues = Object.values(uploadedFields);

  const handleAddPage = (pageNumber) => {
    const generatedPage = createNewPage(pageNumber);
    setFields([...fields, generatedPage]);
  };

  return { fields, setFields, uploadedFields, setUploadedFields, uploadedValues, handleAddPage };
}