import { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { GlobalContext } from '../../App';
import { PageLayout, PhotoBox, SaveButton } from '../../components';
import { routes, API_URL } from '../../conf';
import { useHandleFormSubmit } from '../../hooks/use-handle-form-submit';
import { Alert } from '../../components/alert.component';
import { TOAST_TYPE } from '../../hooks/use-toast';
import { initialScanRodoFields } from '../inspection/initial-rodo-fields';
import { AddPageButton } from '../../components';
import { useMultiplePhotoBox } from '../../hooks/use-multiple-photo-box';

export const InsuranceScanRodoPage = () => {
  const { toast, userInformations, speedCarWorker } = useContext(GlobalContext);
  const { vehicleId } = userInformations;
  const navigate = useNavigate();

  const { person_id, contractor_head, contractor_service } = speedCarWorker;

  const {
    fields: rodoFields,
    setFields: setRodoFields,
    uploadedFields: uploadedRodoFields,
    setUploadedFields: setUploadedRodoFields,
    uploadedValues: uploadedRodoFieldsValues,
    handleAddPage: handleAddRodoPage
  } = useMultiplePhotoBox(initialScanRodoFields);

  const { triggerToast } = toast;

  const { handleSubmit, errorMessage, validated, isLoading } = useHandleFormSubmit(() =>
    axios
      .post(API_URL + '/api/scu/add-rodo-by-vehicle-id', {
        vehicle_id: vehicleId,
        rodo_file: uploadedRodoFieldsValues,
        person: {
          person_id,
          contractor_head,
          contractor_service
        },
        has_technical_review: true,
        token: localStorage.getItem('token')
      })
      .then((response) => {
        if (response.data.success) {
          triggerToast('Zdjęcia zostały pomyślnie zapisane', TOAST_TYPE.SUCCESS);
          navigate(routes.homepage);
        } else {
          const errorMessage = response.data.info || 'Nie udało się zapisać zdjęć - błąd wewnętrzny serwera';
          triggerToast(errorMessage , TOAST_TYPE.DANGER);
        }
      })
      .catch(() => {
        triggerToast('Nie udało się zapisać zdjęć - błąd wewnętrzny serwera', TOAST_TYPE.DANGER);
      })
  );

  return (
    <PageLayout title="Ubezpieczenie bez przeglądu">
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <h2 className="text-center mt-5">Zeskanuj wniosek RODO</h2>
        <Form.Group>
          <div className="d-flex flex-row justify-content-around flex-wrap">
            {rodoFields.map((field) => (
              <PhotoBox
                errorMessage={errorMessage}
                required={field.required}
                key={field.id}
                id={field.id}
                placeholderImage={field.placeholderImage}
                title={field.title}
                generated={field.generated}
                state={rodoFields}
                setState={setRodoFields}
                setPictureState={setUploadedRodoFields}
                pictureState={uploadedRodoFields}
              />
            ))}
            <AddPageButton action={handleAddRodoPage} fieldToAdd={rodoFields.length} />
          </div>
        </Form.Group>
        <Alert style={styles.alert} message={errorMessage} />
        <SaveButton isLoading={isLoading} />
      </Form>
    </PageLayout>
  );
};

const styles = {
  alert: {
    marginTop: '1.5rem'
  }
};
