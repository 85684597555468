import { Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';

import { routes } from '../conf';
import { GlobalContext } from '../App';

export const AztecContainer = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { setLocation } = useContext(GlobalContext);

  useEffect(() => {
    setLocation(currentLocation.pathname);
  }, [currentLocation]);

  return (
    <div className="d-flex flex-column w-50">
      <div className="w-100 d-flex justify-content-between gap-5">
        <div>
          <h2 className="scan-button-label">Zeskanuj AZTEC</h2>
          <div className="scan-button-container">
            <Button
              id="scanAztecButon"
              onClick={() => navigate(routes.scannerPage)}
              className="scan-button"
              style={{ backgroundImage: `url('kod_aztec_test.png')` }}
            >
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
