export const inspectionPhotoFields = [
  {
    id: 'car-back-left',
    placeholderImage: 'l-t.png',
    title: 'Lewy tył',
    required: false
  },
  {
    id: 'car-back-right',
    placeholderImage: 'p-t.png',
    title: 'Prawy tył',
    required: false
  },
  {
    id: 'car-front-left',
    placeholderImage: 'l-p.png',
    title: 'Lewy przód',
    required: false
  },
  {
    id: 'car-front-right',
    placeholderImage: 'p-p.png',
    title: 'Prawy przód',
    required: false
  },
  {
    id: 'mileage',
    placeholderImage: 'car-mileage.png',
    title: 'Zdjęcie przebiegu',
    required: false
  }
];
