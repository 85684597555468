export const initialScanAnnexPhotoFields = [
  {
    id: 'annexes',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];

export const initialScanAnnexPhotoFieldsTwo = [
  {
    id: 'annexes_2',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];

export const initialScanAnnexPhotoFieldsThree = [
  {
    id: 'annexes_3',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];


