import { useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { PageLayout, AztecContainer, UserInfoForm } from '../../components';
import { GlobalContext } from '../../App';
import { routes, insuranceRequiredFields, API_URL } from '../../conf';
import { colors } from '../../shared/constants';
import { useHandleFormSubmit } from '../../hooks/use-handle-form-submit';
import { TOAST_TYPE } from '../../hooks/use-toast';

export const InsuranceInitPage = () => {
  const { userInformations, speedCarWorker, aztecData, setTechnicalReviewId, toast } = useContext(GlobalContext);
  const { phone, inspectionDate, insuranceDate, vehicleId, hasOutdatedData } = userInformations;
  
  const { person_id, contractor_head, contractor_service } = speedCarWorker;
  const { triggerToast } = toast;
  const navigate = useNavigate();
  const { handleSubmit, errorMessage, validated, isLoading } = useHandleFormSubmit(() =>
    axios
      .post(API_URL + '/api/scu/add-client', {
        phone_no: phone,
        next_tech_exam_date: inspectionDate,
        insurance_date_to: insuranceDate,
        person: {
          person_id,
          contractor_head,
          contractor_service
        },
        has_outdated_data: hasOutdatedData,
        has_technical_review: false,
        vehicle_id: vehicleId,
        aztec_data: aztecData,
        token: localStorage.getItem('token'),
        is_technical_review_approval: true,
        is_insurance: true,
      })
      .then((response) => {
        const technicalReviewId = response.data.data;
        setTechnicalReviewId(technicalReviewId);
        if (response.data.success) {
          triggerToast('Klient został pomyślnie dodany', TOAST_TYPE.SUCCESS);
          navigate(routes.insuranceScanRodoPage);
        } else {
          triggerToast('Nie udało się dodać klienta - błąd wewnętrzny serwera', TOAST_TYPE.DANGER);
        }
      })
      .catch(() => {
        triggerToast('Nie udało się dodać klienta - błąd zewnętrzny serwera', TOAST_TYPE.DANGER);
      })
  );

  
  return (
    <PageLayout title="Ubezpieczenie bez przeglądu">
      <div className="d-flex justify-content-between" style={styles.header}>
        <AztecContainer />
      </div>
      <UserInfoForm
        userInformations={userInformations}
        handleSubmit={handleSubmit}
        validated={validated}
        errorMessage={errorMessage}
        isLoading={isLoading}
        requiredUserInfoFields={insuranceRequiredFields}
        isInspection={false}
      />
    </PageLayout>
  );
};

const styles = {
  header: {
    padding: '2rem',
    backgroundColor: colors.accent
  }
};
