import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

import { PageLayout, SaveButton } from '../../components';
import { Alert } from '../../components/alert.component';
import { routes, API_URL } from '../../conf';
import { Modal } from 'react-bootstrap';
import { GlobalContext } from '../../App';
import { TOAST_TYPE } from '../../hooks/use-toast';

const RESULT = {
  POSITIVE: true,
  NEGATIVE: false,
  UNSET: null
};

export const InspectionResultPage = () => {
  const navigate = useNavigate();
  const { technicalReviewId, toast } = useContext(GlobalContext);
  const { triggerToast } = toast;
  const [inspectionResult, setInspectionResult] = useState(RESULT.UNSET);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    if (inspectionResult === RESULT.UNSET) {
      setError('Wybierz wynik przeglądu');
      return;
    }
    axios
      .post(API_URL + '/api/scu/add-technical-review-result', {
        technical_review_id: technicalReviewId,
        technical_review_result: inspectionResult,
        token: localStorage.getItem('token')
      })
      .then((response) => {
        if (response.data.success) {
          triggerToast('Wynik przeglądu został pomyślnie zapisany', TOAST_TYPE.SUCCESS);
          setShowModal(true);
        } else {
          triggerToast('Nie udało się zapisać wyniku przeglądu - błąd wewnętrzny serwera', TOAST_TYPE.DANGER);
        }
      })
      .catch(() => {
        triggerToast('Nie udało się zapisać wyniku przeglądu - błąd zewnętrzny serwera', TOAST_TYPE.DANGER);
      });
  };

  return (
    <PageLayout title="Przegląd techniczny">
      <Modal show={showModal} centered fullscreen={true}>
        <Modal.Header>
          <Modal.Title className="modal-title">
            Czy chcesz teraz zeskanować wniosek RODO?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-container">
          <Button className="modal-button button-positive" onClick={() => navigate(routes.inspectionScanRodoPage)}>
            Tak
          </Button>
          <Button
            className="modal-button button-negative"
            onClick={() => {
              triggerToast('Przegląd techniczny został pomyślnie zakończony', TOAST_TYPE.SUCCESS);
              navigate(routes.homepage);
            }}>
            Nie
          </Button>
        </Modal.Body>
      </Modal>

      <div className="px-4">
        <h2 className="result-text">Wynik przeglądu</h2>
        <Button
          className={`result-button ${inspectionResult === 'POSITIVE' ? 'button-positive-clicked' : 'button-positive'}`}
          onClick={() => setInspectionResult('POSITIVE')}>
          Pozytywny
        </Button>
        <Button
          className={`result-button ${inspectionResult === 'NEGATIVE' ? 'button-negative-clicked' : 'button-negative'}`}
          onClick={() => setInspectionResult('NEGATIVE')}>
          Negatywny
        </Button>
        <Alert message={error} className="alert-error" />
        <SaveButton onClick={handleClick} />
      </div>
    </PageLayout>
  );
};