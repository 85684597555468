import Button from 'react-bootstrap/Button';
import { colors } from '../shared/constants';

export const AddPageButton = ({ action, fieldToAdd, disabled = false }) => {
  return (
    <div className="add-page-button-container d-flex flex-column align-items-center">
      <h3 className="photo-box-label">Dodaj kolejną</h3>
      <Button
        onClick={() => action(fieldToAdd)}
        disabled={disabled}
        className={disabled ? 'disabled' : 'add-page-button'}>
        +
      </Button>
    </div>
  );
};

const styles = {
  addPageButton: {
    height: '100%',
    width: '100%',
    border: `5px dotted ${colors.white}`,
    color: colors.white,
    backgroundColor: colors.accent,
    fontSize: '6rem'
  },
  addPageContainer: {
    width: '18rem',
    height: '18rem',
    padding: '1rem'
  }
};
