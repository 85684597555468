import { useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import Spinner from 'react-bootstrap/Spinner';
import { useMediaQuery } from '@mui/material';

import { colors } from '../shared/constants';
import { GlobalContext } from '../App';
import { TOAST_TYPE } from '../hooks/use-toast';
import { API_URL, TIME_DELAY } from '../conf';

export const PhotoBox = ({
  id,
  placeholderImage,
  title,
  required,
  errorMessage,
  generated,
  pictureState,
  state,
  setState,
  setPictureState
}) => {
  const { toast } = useContext(GlobalContext);
  const { triggerToast } = toast;
  const [source, setSource] = useState('');
  const [base64, setBase64] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const tablet = useMediaQuery('(max-width:768px)');
  
  const styles = {
    box: {
      backgroundColor: colors.white,
      color: colors.white,
      width: tablet ? '180px' : '16rem',
      height: tablet ? '180px' : '14rem',
      fontSize: tablet ? '1.5rem' : '1.8rem',
      border: `5px ${source ? 'solid' : 'dotted'} ${source ? colors.positive : colors.accent}`,
      borderRadius: '5px',
      position: 'relative'
    },
    boxFail: {
      backgroundColor: colors.white,
      color: colors.white,
      width: tablet ? '180px' : '16rem',
      height: tablet ? '180px' : '14rem',
      fontSize: tablet ? '1.5rem' : '1.8rem',
      border: `5px solid ${colors.negative}`,
      borderRadius: '5px',
      position: 'relative'
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
    },
    placeholder: {
      opacity: 0.4
    },
    invisibleInput: {
      display: 'none'
    },
    photoLabel: {
      padding: `${source ? '0' : '1.2rem'}`,
      width: '100%',
      height: '100%'
    },
    boxContainer: {
      padding: '1rem'
    },
    titleError: {
      color: 'blue'
    },
    deleteContainer: {
      position: 'absolute',
      right: -30,
      top: -30,
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      direction: 'column',
      justifyContent: 'center',
      background: 'none',
      color: 'none',
      border: 'none'
    },
    spinnerContainer: {
      wdiht: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center'
    }
  };
  

  const handleCapture = async (target) => {
    setIsLoading(true);

    if (target.files && target.files.length !== 0) {
      const file = target.files[0];
      console.log(`File size: ${file.size} bytes`);

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 3000,
        useWebWorker: true,
        maxIteration: 20,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        console.log(`Compressed file size: ${compressedFile.size} bytes`);
        const newUrl = URL.createObjectURL(compressedFile);
        setSource(newUrl);

        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          const base64Image = reader.result;
          setBase64(base64Image);
          uploadImage(base64Image);
        };
      } catch (error) {
        console.error('Compression error:', error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const uploadImage = async (base64Image) => {
    try {
      const response = await axios.post(API_URL + '/api/scu/upload-file', { file: base64Image, token: localStorage.getItem('token') });
      setPictureState((prevState) => ({ ...prevState, [id]: response.data.data }));
    } catch (error) {
      if (error.response && error.response.status === 413) {
        triggerToast('Zdjęcie jest zbyt duże', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
      } else {
        triggerToast('Nie udało się przesłać zdjęcia - błąd serwera', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const lastItemId = state?.[state?.length - 1]?.id === id;

  const handleDelete = (e) => {
    e.preventDefault();
    const filteredState = state?.filter((field) => field.id !== id);
    setState(filteredState);
    delete pictureState[id];
    setPictureState(pictureState);
  };
  const formValidationFailed = errorMessage?.length > 0;

  return (
    <div className="d-flex flex-column align-items-center" style={styles.boxContainer}>
      <h3 className={`photo-box-label ${!source && formValidationFailed && required ? 'text-danger' : ''}`}>{title}</h3>
      <div style={source ? styles.box : formValidationFailed && required ? styles.boxFail : styles.box}>
        {lastItemId && generated && (
          <button onClick={(e) => handleDelete(e)} style={styles.deleteContainer}>
            <XCircleFill className="photobox-delete-button" />
          </button>
        )}
        <label htmlFor={id} style={styles.photoLabel}>
          {isLoading ? 
            (
              <div style={styles.spinnerContainer}>
                <Spinner animation="border" role="status" variant="primary" style={{ width: "80px", height: "80px" }}/>
              </div>
            ) : 
            (
              <>
                <img
                  src={source ? source : placeholderImage}
                  style={source ? styles.image : { ...styles.image, ...styles.placeholder }}
                  alt="placeholder"
                ></img>
              </>
            )
          }
        </label>
      </div>
      <Form.Control
        required={required}
        id={id}
        type="file"
        capture="environment"
        onChange={(e) => {
          handleCapture(e.target);
        }}
        style={styles.invisibleInput}
      />
    </div>
  );
};

