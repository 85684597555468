export const colors = {
  white: '#FFFFFF',
  accent: '#084588',
  negative: '#ef5350',
  positive: '#26a69a'
};

export const dateFormats = {
  default: 'YYYY-MM-DD',
  dots: 'DD.MM.YYYY',
};

export const ENCODED_EXAMPLE =
  'owQAANtYAAJDAP8xAHwAQgBBAP9TADIANQA5AO80ADgSMn4qMAA29wIzEv9QAFIARQBaAO9ZAEQOTv8AVAAgAE0ASX1rVNsKGkwAvVWKTO8mTl9rEi4q30tu0wC+TENX2woBQX73WQBTE95XK9cST0q/SapUAEt4IzUfMu72LRLWCjm3jnz4HyA9UzbnIkqeQUTtPhJH3wBZQ0R2MsIFulF9n1H7HjcAQQvvRDowdgLG71IAUw+9MTIx8J9CeKdFO7YxBt9MHjAAvXxPoN4z9xNJLrxP2lMeG0X3Olcaa2ZaA9z7145QHr45xzDw3jVYn3WnLfcjLR/3fAAtA9587jHhttfHWqKHRKeGertWzt5eSxpXe0bf70EGQwdHvUxiU+9aUk5JeCZFPUdQ8GdT3ZNTxR4Lr1oGT8sALrd8AzMXyjR+CjUANg/3MQdbvDQGODH/xzcPYYn/YhtuUqrXvlQKg0N7g5tBfKdEFzJ9kzCDExSHNzHnurNNP+NlewoqT8XjqC/uVx+8MRI08TDbL4NLD0MyVxNuTiwAMNrnM0MbDFB1q3cxp9o1h9PSo1+CjqtIDodEZ95TBkJ+VwBZwduylwijF/dDN3fpQfd3ND6vNiveNwZ8m4voMQA0jdMT5zN9L3zuEwu3MVcwbg8x8gJOFs0zOa4LN+EmJJJJJUD/AA==S';

export const ATTR_TO_INDEX = {
  seria_dr: 1,
  organ_wydajacy_nazwa: 3,
  organ_wydajacy_ulica: 4,
  organ_wydajacy_miasto: 5,
  nr_rejestracyjny: 7,
  marka_pojazdu: 8,
  typ_pojazdu: 9,
  wariant_pojazdu: 10,
  wersja_pojazdu: 11,
  model_pojazdu: 12,
  vin_nr_nadwozia: 13,
  data_wydania_aktualnego_dr: 14,
  nazwa_posiadacza_dr: 16,
  imiona_posiadacza_dr: 17,
  nazwisko_posiadacza_dr: 18,
  pesel_regon_posiadacza_dr: 20,
  kod_pocztowy_posiadacza_dr: 21,
  miasto_posiadacza_dr: 22,
  powiat_posiadacza_dr: 23,
  ulica_posiadacza_dr: 24,
  nr_domu_posiadacza_dr: 25,
  nr_lokalu_posiadacza_dr: 26,
  nazwa_wlasciciela_pojazdu: 27,
  imiona_wlasciciela_pojazdu: 28,
  nazwisko_wlasciciela_pojazdu: 29,
  pesel_regon_wlasciciela_pojazdu: 31,
  kod_pocztowy_wlasciciela_pojazdu: 32,
  miasto_wlasciciela_pojazdu: 33,
  powiat_wlasciciela_pojazdu: 34,
  ulica_wlasciciela_pojazdu: 35,
  nr_domu_wlasciciela_pojazdu: 36,
  nr_lokalu_wlasciciela_pojazdu: 37,
  maksymalna_masa_calkowita: 38,
  dopuszczalna_masa_calkowita_pojazdu: 39,
  dopuszczalna_masa_calkowita_zespolu_pojazdow: 40,
  masa_wlasna: 41,
  kategoria_pojazdu: 42,
  nr_swiadectwa_homologacji_typu_pojazdu: 43,
  liczba_osi: 44,
  maksymalna_masa_calkowita_przyczepy_z_hamulcem: 45,
  maksymalna_masa_calkowita_przyczepy_bez_hamulca: 46,
  stosunek_mocy_do_ciezaru: 47,
  pojemnosc: 48,
  moc_silnika: 49,
  rodzaj_paliwa: 50,
  data_pierwszej_rejestracji: 51,
  miejsca_siedzace: 52,
  miejsca_stojace: 53,
  rodzaj_pojazdu: 54,
  przeznaczenie: 55,
  rok_produkcji: 56,
  dopuszczalna_ladownosc: 57,
  najwiekszy_dopuszczalny_nacisk_osi: 58,
  nr_karty_pojazdu: 59,
  kod_its: 60
};
