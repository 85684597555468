import { useState } from 'react';

export const useHandleFormSubmit = (apiCall) => {
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      const errorMessage = 'Wypełnij wszystkie potrzebne pola';
      setErrorMessage(errorMessage);
      return Promise.reject(errorMessage).finally(() => setValidated(true));
    } else {
      setErrorMessage('');
      setIsLoading(true);
      return apiCall(form)
        .catch((error) => setErrorMessage(error))
        .finally(() => {
          setIsLoading(false);
          setValidated(true);
        });
    }
  };

  return {
    handleSubmit,
    validated,
    errorMessage,
    isLoading
  };
};
