import { useContext } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastWrapper from 'react-bootstrap/ToastContainer';

import { GlobalContext } from '../App';

export const ToastContainer = () => {
  const { toast } = useContext(GlobalContext);
  const { showToast, setShowToast, toastConfig } = toast;

  return (
    <ToastWrapper className="toast-conatiner">
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        autohide={true}
        delay={toastConfig.delay}
        bg={toastConfig.type}
        className="toast"
      >
        <Toast.Header className='toast-header'>{toastConfig.text}</Toast.Header>
      </Toast>
    </ToastWrapper>
  );
};