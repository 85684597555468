import { nanoid } from 'nanoid';

export const createNewPage = (pageNumber, title = 'Strona ') => {
  const generateNewPolicyPage = {
    id: nanoid(),
    placeholderImage: 'document.png',
    title: `${title} ${pageNumber + 1}`,
    generated: true,
    required: true
  };

  return generateNewPolicyPage;
};
