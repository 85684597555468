import React, { useState } from 'react';
import axios from 'axios';
import { Scanner } from '../../components/scanner.component';
import { ENCODED_EXAMPLE, ATTR_TO_INDEX } from '../../shared/constants';
import { API_URL } from '../../conf';

export const KukosoftPage = () => {
  const [kukosoftData, setKukosoftData] = useState(null);
  const [carQData, setCarQData] = useState(null);
  const [encodedInput, setEncodedInput] = useState(ENCODED_EXAMPLE);
  const [isScanning, setIsScanning] = useState(false);
  const [timeKukosoft, setTimeKukosoft] = useState(0);
  const [timeCarQ, setTimeCarQ] = useState(0);

  const decodeWithKukosoft = (aztec) => {
    const t1 = Date.now();
    axios
      .post(API_URL + '/api/kukosoft/decode', { aztec })
      .then((response) => {
        const t2 = Date.now();
        setTimeKukosoft(t2 - t1);
        setKukosoftData(parseData(response.data));
      })
      .catch((err) => {
        console.log('[kukosoft] error', err);
      });
  };

  const decodeWithCarQ = (aztec) => {
    const t1 = Date.now();
    axios
      .post(API_URL + '/api/carq/decode', { aztec })
      .then((response) => {
        const t2 = Date.now();
        setTimeCarQ(t2 - t1);
        setCarQData(response.data.decodedAztec);
      })
      .catch((err) => {
        console.log('[carQ] error', err);
      });
  };

  const parseData = (data) => {
    const dataArray = data.split('|');
    const parsedData = Object.keys(ATTR_TO_INDEX).reduce((obj, attr) => {
      const index = ATTR_TO_INDEX[attr];
      obj[attr] = dataArray[index];
      return obj;
    }, {});
    return parsedData;
  };

  const handleScan = (aztecString) => {
    setIsScanning(false);
    setEncodedInput(aztecString);
  };

  const renderTime = (time) => {
    if (!time) {
      return null;
    }
    return <span style={styles.time}>({(time / 1000).toFixed(2)}s)</span>;
  };

  return (
    <div>
      <div style={styles.scannerContainer}>
        <button onClick={() => setIsScanning(!isScanning)}>{isScanning ? 'Zamknij skaner' : 'Otwórz skaner'}</button>
        {isScanning && <Scanner customScanHandler={handleScan} />}
      </div>
      <div style={styles.inputContainer}>
        <textarea style={styles.input} value={encodedInput} onChange={(e) => setEncodedInput(e.target.value)} />
        <button onClick={() => setEncodedInput('')}>Resetuj</button>
      </div>
      <div style={styles.codes}>
        <div style={styles.code}>
          <div style={styles.header}>
            <h3>Kukosoft</h3>
            <button onClick={() => decodeWithKukosoft(encodedInput)}>Dekoduj</button> {renderTime(timeKukosoft)}
          </div>
          {!kukosoftData && <p>Brak danych</p>}
          {kukosoftData && <pre>{JSON.stringify(kukosoftData, null, 2)}</pre>}
        </div>
        <div style={styles.code}>
          <div style={styles.header}>
            <h3>CarQ</h3>
            <button onClick={() => decodeWithCarQ(encodedInput)}>Dekoduj</button> {renderTime(timeCarQ)}
          </div>
          {!carQData && <p>Brak danych</p>}
          {carQData && <pre>{JSON.stringify(carQData, null, 2)}</pre>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  scannerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 28,
    margin: 28
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 28,
    margin: 28
  },
  input: {
    width: '92vw',
    height: 200
  },
  codes: {
    display: 'flex',
    margin: 28
  },
  header: {
    display: 'flex',
    gap: 24
  },
  code: {
    width: '50%'
  },
  time: {
    display: 'flex',
    alignItems: 'center',
  }
};
