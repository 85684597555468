import { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { GlobalContext } from '../../App';
import { SaveButton, PageLayout } from '../../components';
import { Alert } from '../../components/alert.component';
import { TOAST_TYPE } from '../../hooks/use-toast';
import { TIME_DELAY, API_URL, routes } from '../../conf';

export const ChooseSpeedCarPlace = () => {
  const { toast } = useContext(GlobalContext);
  const { triggerToast } = toast;
  const [deviceId, setDeviceId] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async (deviceId, event) => {
    event.preventDefault();
    await axios.post(API_URL + '/api/kukosoft/login', { deviceId: deviceId })
    .then((response) => {
      setIsSubmitted(true);
      navigate(routes.homepage);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('placeName', response.data.placeName);
      localStorage.setItem('deviceId', response.data.deviceId);
      triggerToast('Zalogowano pomyślnie', TOAST_TYPE.SUCCESS, TIME_DELAY.LONG);
    })
    .catch(() => {
      setIsSubmitted(false);
      triggerToast('Brak urządzenia w bazie danych', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
    });
  };

  return (
    <PageLayout title="" withLogo={true}>
      <h1 className="main-title">Zaloguj się na urządzenie</h1>
      <Form className="form-container" onSubmit={(e) => handleSubmit(deviceId, e)} noValidate>
        <Form.Group>
          <h1 className="input-label">Wpisz identyfikator urządzenia</h1>
          <Form.Control
            required={true}
            className="input-type-text text-uppercase"
            type="text"
            value={deviceId}
            onChange={(e) => setDeviceId(e.currentTarget.value.toLocaleUpperCase())}
            isInvalid={deviceId === '' && !isSubmitted}
          />
          {!isSubmitted && (
            <Form.Control.Feedback type="invalid">
              <Alert message={'Wpisz identyfikator urządzenia'} />
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <SaveButton />
      </Form>
    </PageLayout>
  );
};
