import AlertBootstrap from 'react-bootstrap/Alert';

export const ALERT_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark'
};

export const Alert = ({ message, variant = ALERT_TYPE.DANGER }) => {
  if (!message) return null;
  return (
    <AlertBootstrap
      
      key={variant}
      variant={variant}
      className="alert d-flex align-items-center justify-content-center">
      {message}
    </AlertBootstrap>
  );
};

