export const initialScanAccoutBooksPhotoFields = [
  {
    id: 'accoutBooks',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];

export const initialScanAccoutBooksPhotoFieldsTwo = [
  {
    id: 'accoutBooks_2',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];

export const initialScanAccoutBooksPhotoFieldsThree = [
  {
    id: 'accoutBooks_2',
    placeholderImage: 'document.png',
    title: 'Strona 1',
    required: true,
    generated: false
  }
];
